import React from 'react';

import { Title, Button } from '../components/global';

const NotFoundPage = () => (
  <>
    <Title prefix="404" />
    <section className="flex justify-center items-center min-h-screen bg-champagne-blue">
      <div className="text-center px-4">
        <h1 className="text-h2">Hier ist etwas schief gelaufen</h1>
        <p className="mt-8 mb-16 md:text-xl">
          Die angeforderte Seite konnte leider nicht gefunden werden.
        </p>
        <Button to="/">Zurück zur Startseite</Button>
      </div>
    </section>
  </>
);

export default NotFoundPage;
